import { Form, Formik } from "formik";
import * as Yup from "yup";

import style from "./RegisterForm.module.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BasicInputProps, Input, Radio, SubmitButton } from "@ui";
import { useRegisterMutation } from "@RTKQuery/account/api";
import Tenant from "@interfaces/tenant";

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Ваше ім'я та прізвище - обов'язкове поле"),
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
  subDomainName: Yup.string()
    .matches(/^[a-z0-9-]+$/, "Тільки малі латинські літери, цифри та дефіс")
    .min(4, "Не менше 4 символів")
    .max(16, "Не більше 16 символів")
    .required("Назва домену - обов'язкове поле"),
});

export function RegisterForm() {
  const [
    register,
    { isUninitialized, isSuccess, isLoading, isError, error, reset },
  ] = useRegisterMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        reset();
      }, 1700);
    }
  }, [isError]);

  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        subDomainName: "",
        accountType: "dropper",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        register({
          first_name: values.fullName.trim().split(" ")[0],
          last_name: values.fullName.trim().split(" ")[1] || "",
          email: values.email.trim(),
          country: (navigator.language || navigator.languages[0]).split("-")[1],
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          lang: (navigator.language || navigator.languages[0]).split("-")[0],
          subdomain: values.subDomainName.trim(),
          account_type: values.accountType as Tenant["account_type"],
        })
          .unwrap()
          .then((data) => {
            window.localStorage.setItem("service_token", data.service_token);
            navigate(
              process.env.NODE_ENV === "development"
                ? "/account/auth/verify"
                : "/auth/verify",
            );
          })
          .catch(() => {});
      }}
    >
      {(props) => (
        <Form>
          <div className={style["inputsBox"]}>
            <Input<BasicInputProps>
              formikName="fullName"
              label="Ваше ім'я та прізвище"
              inputType="text"
            />
            <Input<BasicInputProps>
              formikName="email"
              label="E-mail"
              inputType="email"
              placeholder="Приклад: yourmail@gmai.com"
            />
            <div>
              <div className={style["inputsBoxDomen"]}>
                <p className={style["prefix"]}>https://</p>
                <div className="grow">
                  <Input<BasicInputProps>
                    formikName="subDomainName"
                    label="Назва домену"
                    inputType="text"
                    helperTextVisibility={false}
                  />
                </div>
                <p className={style["suffix"]}>.levelup.com.ua</p>
              </div>
              <div className={style["errorMessage"]}>
                {
                  props.touched.subDomainName
                  ? props.errors.subDomainName
                  : null
                }
              </div>
            </div>
            <div className={style["radioBox"]}>
              <Radio
                checked={props.values.accountType === "dropper"}
                onChange={() => {
                  props.setFieldValue("accountType", "dropper");
                }}
                label="Дропшипер"
              />
              <Radio
                checked={props.values.accountType === "supplier"}
                onChange={() => {
                  props.setFieldValue("accountType", "supplier");
                }}
                label="Постачальник"
              />
            </div>
          </div>
          <div className={style["submitButtonBox"]}>
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Зареєструватись"
              successText="Успішно"
              errorText="Не вдалось зареєструватись"
              errorMesage={
                error
                  ? (error as unknown as { data: { error: string } }).data
                      ?.error
                  : ""
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
