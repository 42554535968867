import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Button, SubmitButton, Input } from "@ui";
import { useProductPropertyValuesAllQuery, useProductPropertyValuesCreateMutation } from "@RTKQuery/product-properties/api";



export function CreatePropertyValueForm() {
  const { propertyId } = useParams();

  const { data: existingProperties } = useProductPropertyValuesAllQuery(
    Number(propertyId || "-1"),
  );

  const [
    createProductPropertyValues,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductPropertyValuesCreateMutation({
    fixedCacheKey: "add-book-value",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        friends: [
          {
            nameUA: "",
            nameRU: "",
          },
        ],
      }}
      validationSchema={
        Yup.object().shape({
          friends: Yup.array().of(
            Yup.object().shape({
              nameUA: Yup.string()
                .max(36, "Максимальна довжина рядка 36 символів")
                .test(
                  "is-existing",
                  "Таке значення уже існує",
                  (value, context) => !existingProperties?.some(property => property.name.ua === value),
                )
                .required("Назва UA - обов'язкове поле"),
              nameRU: Yup.string()
                .max(36, "Максимальна довжина рядка 36 символів")
                .test(
                  "is-existing",
                  "Таке значення уже існує",
                  (value, context) => !existingProperties?.some(property => property.name.ru === value),
                )
                .required("Назва RU - обов'язкове поле"),
            }),
          ),
        })
      }
      onSubmit={(values, actions) => {
        createProductPropertyValues({
          property_id: Number(propertyId),
          listNames: values.friends.map((item) => ({
            name: {
              ua: item.nameUA.trim(),
              ru: item.nameRU.trim(),
            },
          })),
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => { });
      }}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="friends">
            {({ insert, remove, push }) => (
              <>
                <div className="scroll max-h-[300px] overflow-auto pr-[10px]">
                  {values.friends.length > 0 &&
                    values.friends.map((friend, index) => (
                      <div
                        key={index}
                        className="mb-[10px] flex gap-[15px] max-sm:mb-[30px]"
                      >
                        <div className="flex w-full gap-[15px] max-sm:flex-col max-sm:gap-[5px]">
                          <div className="w-[50%] max-sm:w-[100%]">
                            <Input
                              formikName={`friends.${index}.nameUA`}
                              label="Назва UA"
                              inputType="text"
                            />
                          </div>
                          <div className="w-[50%] max-sm:w-[100%]">
                            <Input
                              formikName={`friends.${index}.nameRU`}
                              label="Назва RU"
                              inputType="text"
                            />
                          </div>
                        </div>
                        <div className="pt-[25px] max-sm:my-auto max-sm:pt-0">
                          <Button
                            type="delete"
                            tabIndex={-1}
                            onClick={(e) => {
                              remove(index);
                              e.currentTarget.blur();
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <AddFormElement push={push} />
                <SubmitButton
                  type="submit"
                  disabled={isLoading}
                  isUninitialized={isUninitialized}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isError={isError}
                  uninitializedText="Зберегти"
                  successText="Успішно збережено"
                  errorText="Не вдалось зберегти"
                  errorMesage={
                    error
                      ? (error as unknown as { data: { error: string } }).data
                        .error
                      : ""
                  }
                />
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

function AddFormElement({ push }: { push: <X = any>(obj: X) => void }) {
  useEffect(() => {
    const handlerEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        push({ nameUA: "", nameRU: "" });
      }
    };

    window.addEventListener("keyup", handlerEnter);

    return () => {
      window.removeEventListener("keyup", handlerEnter);
    };
  }, []);

  return (
    <div className="mb-[40px] flex justify-center pr-[35px]">
      <Button
        type="add"
        onClick={(e) => {
          push({ nameUA: "", nameRU: "" });
          e.currentTarget.blur();
        }}
      />
    </div>
  );
}
