import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { accountMainRouterConfig } from "./routers/account/main.router";
import { accountAuthRouterConfig } from "./routers/account/auth.router";
import { CRMMainRouterConfig } from "./routers/crm/main.router";
import { CRMAuthRouterConfig } from "./routers/crm/auth.router";
import { LoadingBackdrop } from "./components/common/LoadingBackdrop";
import { useLazyCRMUserQuery } from "@RTKQuery/CRMUser/api";
import {
  accountLogIn,
  accountLogOut,
  CRMLogIn,
  CRMLogOut,
} from "@global-state/global/slice";
import { useLazyAccountQuery } from "@RTKQuery/account/api";

export default function App() {
  const [
    getCRMUser,
    {
      isFetching: isFetchingCRMUser,
      isUninitialized: isUninitializedCRMser,
      data,
    },
  ] = useLazyCRMUserQuery({
    selectFromResult: ({ data, ...args }) => ({
      data: {
        isActiveUnloading: Boolean(
          data?.settings.active_functionalities.find((itemId) => itemId === 1),
        ),
      },
      ...args,
    }),
  });

  const [
    getAccount,
    { isFetching: isFetchingAccount, isUninitialized: isUninitializedAccount },
  ] = useLazyAccountQuery();

  const isAuth = useAppSelector((state) => state.global);

  const rootPath =
    process.env.NODE_ENV === "development"
      ? window.location.pathname.split("/")[1] || "account"
      : window.location.host.split(".")[0];

  window.localStorage.setItem("subdomain", rootPath);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rootPath === "account" || rootPath === "") {
      getAccount(void 0, true)
        .unwrap()
        .then(() => {
          dispatch(accountLogIn());
        })
        .catch(() => {
          dispatch(accountLogOut());
        });
    } else {
      getCRMUser(void 0, true)
        .unwrap()
        .then(() => {
          dispatch(CRMLogIn());
        })
        .catch(() => {
          dispatch(CRMLogOut());
        });
    }
  }, []);

  let currentRouterConfig = null;

  if (rootPath === "account" || rootPath === "") {
    if (isAuth.accountAuth) {
      currentRouterConfig = accountMainRouterConfig;
    } else {
      currentRouterConfig = accountAuthRouterConfig;
    }
  } else {
    if (isAuth.CRMAuth) {
      currentRouterConfig = CRMMainRouterConfig(rootPath, [
        data.isActiveUnloading ? "unloading" : "",
      ]);
    } else {
      currentRouterConfig = CRMAuthRouterConfig(rootPath);
    }
  }

  document.body.classList.add(
    "bg-backdrop-main-light",
    "dark:bg-backdrop-main-dark",
  );

  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
    window.localStorage.setItem("theme", "dark");
  } else {
    document.documentElement.classList.remove("dark");
    window.localStorage.setItem("theme", "light");
  }

  if (
    (isUninitializedAccount && isUninitializedCRMser) ||
    isFetchingAccount ||
    isFetchingCRMUser
  ) {
    return <LoadingBackdrop />;
  } else {
    return <RouterProvider router={createBrowserRouter(currentRouterConfig)} />;
  }
}