import { redirect } from "react-router-dom";

import { CRMMainLayout } from "../../pages/layout/crm";

import {
  OrderPage,
  CustomerPage,
  ClientsPage,
  SuppliersPage,
  ProductAllPage,
  ProductOnePage,
  SettingsPage,
  SettingsOrderPage,
  SettingsSourcesPage,
  SettingsFinancePage,
  SettingsRolesPage,
  AnalyticsProductsPage,
  AnalyticsOrderPage,
  AnalyticsManagersPage,
  AnalyticsSuppliersPage,
  BillingPage,
  UnloadingAllPage,
  UnloadingOnePage,
  StockAllPage,
  StockOnePage,
  ProductPropertiesPage,
  ProductImportPage,
  AdditionalServicesPage,
  SystemNotificationsAllPage,
  SystemNotificationsOnePage,
} from "../../pages";

import { CreateProduct, EditProduct } from "../../pages/catalog/products";
import { CreateUnloading, EditUnloading } from "pages/catalog/unloading";
import {
  MultipleActionsProperties,
  MultipleActionsValues,
  TableProperties,
  TableValues,
} from "components/catalog/productProperties";

const routes = (crm: string, hiddenPagesArr?: ("unloading" | "")[]) => [
  {
    path: "orders",
    element: <OrderPage />,
  },
  {
    path: "customers",
    element: <CustomerPage />,
  },
  {
    path: "clients",
    element: <ClientsPage />,
  },
  {
    path: "suppliers",
    element: <SuppliersPage />,
  },
  {
    path: "catalog",
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath =
        process.env.NODE_ENV === "development" ? `/${crm}/catalog` : "/catalog";

      if (pathname === rootPath || pathname === rootPath + "/") {
        const searchParam = window.localStorage.getItem("searchСatalogPage");
        const searchQuery = searchParam ? "&search=" + encodeURIComponent(searchParam) : "";

        return redirect(
          rootPath +
          "/" +
          (window.localStorage.getItem("categoryCatalogPage") || "0") +
          "/?limit=" +
          (window.localStorage.getItem("limitСatalogPage") || "20") +
          "&offset=" +
          (window.localStorage.getItem("offsetСatalogPage") || "0") +
          searchQuery,
        );
      }

      return null;
    },
    children: [
      {
        path: ":categoryId",
        element: <ProductAllPage />,
      },
    ],
  },
  {
    path: "catalog/import",
    element: <ProductImportPage />,
  },
  {
    path: "catalog/:categoryId/product/:productId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "catalog/:categoryId/product/:productId/variation/:variationId",
    element: (
      <EditProduct>
        <ProductOnePage />
      </EditProduct>
    ),
  },
  {
    path: "catalog/:categoryId/product/add",
    element: (
      <CreateProduct>
        <ProductOnePage />
      </CreateProduct>
    ),
  },
  {
    path: "product-properties",
    element: <ProductPropertiesPage />,
    children: [
      {
        path: ":group",
        element: (
          <>
            <TableProperties />
            <MultipleActionsProperties />
          </>
        ),
      },
      {
        path: ":group/property/:propertyId",
        element: (
          <>
            <TableValues />
            <MultipleActionsValues />
          </>
        ),
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsPage />,
  },
  {
    path: "settings/order",
    element: <SettingsOrderPage />,
  },
  {
    path: "settings/sources",
    element: <SettingsSourcesPage />,
  },
  {
    path: "system-notifications",
    element: <SystemNotificationsAllPage />,
  },
  {
    path: "system-notifications/:notificationId",
    element: <SystemNotificationsOnePage />,
  },
  {
    path: "settings/finance",
    element: <SettingsFinancePage />,
  },
  {
    path: "settings/roles",
    element: <SettingsRolesPage />,
  },
  {
    path: "settings/additional-services",
    element: <AdditionalServicesPage />,
  },
  {
    path: "analytics/products",
    element: <AnalyticsProductsPage />,
  },
  {
    path: "analytics/order",
    element: <AnalyticsOrderPage />,
  },
  {
    path: "analytics/managers",
    element: <AnalyticsManagersPage />,
  },
  {
    path: "analytics/suppliers",
    element: <AnalyticsSuppliersPage />,
  },
  {
    path: "billing",
    element: <BillingPage />,
  },
  ...(() => {
    if (hiddenPagesArr?.includes("unloading")) {
      return [
        {
          path: "unloading/:marketplaceId",
          element: <UnloadingAllPage />,
        },
        {
          path: "unloading/:marketplaceId/link/:unloadingId",
          element: (
            <EditUnloading>
              {() => <UnloadingOnePage mode="update" />}
            </EditUnloading>
          ),
        },
        {
          path: "unloading/:marketplaceId/link/add",
          element: (
            <CreateUnloading>
              {() => <UnloadingOnePage mode="create" />}
            </CreateUnloading>
          ),
        },
      ];
    } else return [];
  })(),
  {
    path: "stock",
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath =
        process.env.NODE_ENV === "development" ? `/${crm}/stock` : "/stock";

      if (pathname === rootPath || pathname === rootPath + "/") {
        const searchParam = window.localStorage.getItem("searchStockPage");
        const searchQuery = searchParam ? "&search=" + encodeURIComponent(searchParam) : "";

        return redirect(
          rootPath +
          "/" +
          (window.localStorage.getItem("categoryStockPage") || "0") +
          "/?limit=" +
          (window.localStorage.getItem("limitStockPage") || "20") +
          "&offset=" +
          (window.localStorage.getItem("offsetStockPage") || "0") +
          searchQuery,
        );
      }

      return null;
    },
    children: [
      {
        path: ":categoryId",
        element: <StockAllPage />,
      },
    ],
  },
  {
    path: "stock/:categoryId/product/:productId",
    element: <StockOnePage />,
  },
];

export const CRMMainRouterConfig = (
  crm: string,
  hiddenPagesArr?: ("unloading" | "")[],
) => [
    {
      path: "*",
      loader: ({ request }: { request: any }) => {
        const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

        return redirect(rootPath + "/catalog");
      },
    },
    {
      path: process.env.NODE_ENV === "development" ? "/:crm/" : "/",
      element: <CRMMainLayout />,
      loader: ({ request }: { request: any }) => {
        const pathname = new URL(request.url).pathname;

        const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

        if (pathname === rootPath || pathname === rootPath + "/") {
          return redirect(rootPath + "/catalog");
        }

        return null;
      },
      children: routes(crm, hiddenPagesArr),
    },
  ];
